@use 'sass:math';

.list-separated {
  @include clearlist;

  li {
    border-bottom: $avr-border-width-100-gray-30;
    padding: $avr-space-base 0;

    &:last-of-type {
      border-bottom: 0;
    }
  }
}

.list-item {
  border-bottom: $avr-border-width-100-gray-30;
  position: relative;

  &:last-child {
    border-bottom: 0;
  }
}

//================
// Extends bootstrap's list-inline
//================

.list-inline {
  margin: 0;

  &--wrapping {
    > li {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
}

//================
// Action list (custom component):
// list of links, each with header, description and an icon
//================
.action-list {
  @include clearlist;

  li {
    border-bottom: 1px solid $table-border-color;

    &:last-child {
      border-bottom: none;
    }
  }
}

.action-link {
  display: block;
  position: relative;
  color: $avr-color-gray-10;
  padding: $avr-space-m ($avr-icon-size-m + $avr-space-m) $avr-space-m 0;

  h4 {
    margin: 0 0 $avr-space-base 0;
  }

  .media-left {
    float: left;
    margin-right: $avr-space-m;
    font-size: $avr-icon-size-l;
    width: auto;
    padding: 0;
  }

  .arrow {
    position: absolute;
    top: 50%;
    font-size: 18px;
    right: $avr-space-m;
    margin-top: -12px;
  }

  &__disabled-badge {
    margin-top: $avr-space-s;
  }

  &:hover:not(&--disabled) {
    text-decoration: none;
    color: $avr-color-gray-10;

    h4 {
      color: $avr-color-blue-50;
    }

    .avricon {
      color: $avr-color-blue-50;
    }
  }

  &--disabled,
  &--disabled:hover {
    color: $text-muted;
    cursor: default;
  }
}

.modal-body .action-list {
  background-color: $avr-color-gray-95;
  margin: -$modal-inner-padding;
  border-radius: 0 0 $avr-modal-border-radius $avr-modal-border-radius;

  .action-link {
    padding-left: $modal-inner-padding;
    padding-right: $avr-icon-size-m + 2 * $modal-inner-padding;

    .description {
      color: $avr-color-gray-30;
    }

    .arrow {
      right: $modal-inner-padding;
    }
  }
}

.property-list {
  margin-left: math.div(-$grid-gutter-width, 2);
  margin-right: math.div(-$grid-gutter-width, 2);

  @include clearfix;

  &.property-list--right {
    margin-right: 0;
  }

  &.property-list--horizontal {
    &.row {
      .property-label,
      .property-value {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .property-label,
    .property-value {
      width: 100%;
      float: none;
    }

    .property-label {
      color: $avr-color-gray-30;
    }

    .property-value {
      padding-top: 0;
      margin-bottom: $avr-space-s;
    }
  }

  .property-label,
  .property-value {
    float: left;
    padding: 3px $avr-space-m;

    &.no-line-spacing {
      padding-top: 0;
    }
  }

  .property-label {
    clear: both;
    width: $one-third;
    margin-bottom: 5px;

    &--semibold {
      font-weight: $avr-font-weight-semibold;
    }

    &.nested {
      padding-left: 40px;
    }
  }

  .property-value {
    width: $two-thirds;
    word-wrap: break-word;
    font-weight: $avr-font-weight-semibold;

    &--bold {
      font-weight: $avr-font-weight-bold;
    }

    &--right {
      text-align: right;
    }

    &.no-value {
      &,
      a {
        @include text-empty;
      }
    }
  }

  .property-separator {
    height: 30px;
  }

  .property-editable {
    a {
      color: $text-color;

      .avricon-pencil {
        visibility: hidden;
      }
    }

    a:hover {
      text-decoration: none;

      .avricon-pencil {
        visibility: visible;
      }
    }
  }

  .avr-input.inline-editing {
    clear: both;

    @include clearfix;

    .display-value {
      pre {
        padding: 0;
        margin-bottom: 0;
        border: 0;
        border-radius: 0;
        background: none;
        font-family: $avr-font-family-plain;
        font-size: 14px;
        color: $avr-color-gray-10;
        float: left;
        white-space: pre-wrap;
      }
    }

    .display-value.no-value {
      pre {
        @include text-empty;
      }
    }
  }

  .panel & {
    .property-label,
    .property-value {
      width: 50%;
    }
  }

  &--condensed {
    .property-label,
    .property-value {
      padding: {
        top: 0;
        bottom: 0;
      }
    }
  }
}

.list {
  @include clearlist;

  & > li {
    border: none;
    margin-top: $avr-space-s;

    &:first-child {
      margin-top: 0;
    }
  }
}

.comma-separated {
  @include clearlist;

  display: inline;

  > li {
    display: inline;
  }

  > li:after {
    content: ', ';
  }

  > li:last-of-type {
    &:after {
      content: '';
    }
  }
}
