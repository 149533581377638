// Overwrites for Bootstrap modals
body:not(.modal-open-bottom-up) .modal {
  &.fade {
    transition: opacity 0s;

    .modal-dialog {
      @include translate(0, 0);

      top: -25%;
      transition: top 0s;
    }
  }

  &.in {
    transition: opacity 0.23s cubic-bezier(0, 0, 0.2, 1);

    .modal-dialog {
      top: 0;
      transition: top 0.23s cubic-bezier(0, 0, 0.2, 1);
    }
  }
}

body.modal-open-bottom-up .modal {
  &.fade {
    transition: opacity 0s;

    .modal-dialog {
      transform: translate3d(0, 100vh, 0);
    }
  }

  &.in {
    transition: opacity 0.23s cubic-bezier(0, 0, 0.2, 1);

    .modal-dialog {
      transform: translate3d(0, 0, 0);
    }
  }
}

.modal-dialog.sent-to-back:after {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: $z-index-layer-2;
  background-color: $avr-depth-z300-backdrop;
  opacity: 0.7;
}

.modal-content {
  border: none;
  border-radius: $avr-modal-border-radius;
  background-clip: initial;
  box-shadow: $avr-depth-z300-shadow;

  @include tablet {
    box-shadow: $avr-depth-z300-shadow;
  }
}

.modal-content-section {
  background: $avr-color-gray-95;
  border-bottom: 1px solid $table-border-color;
  margin: (-$avr-space-m) (-$avr-space-m) $avr-space-m (-$avr-space-m);
  padding: $avr-space-m;
}

.modal-content-section-inner {
  margin: $avr-space-m 0;
  border-bottom: none;
}

.modal-header,
.modal-header-image {
  .close {
    @extend %avricon-base;
    @extend .avricon-remove;

    font-size: $font-size-h4;
    padding: 0;
    opacity: 1;
    text-shadow: none;
    width: 20px;
    height: 20px;
    order: 2;
    color: $avr-color-gray-10;
  }
}

.modal-header {
  border: none;
  padding: $avr-space-xl $modal-inner-padding $modal-inner-padding;
  display: flex;
  align-items: center;

  &:after,
  &:before {
    content: none;
  }

  & > span,
  & > .modal-title-wrapper {
    flex-grow: 1;
  }
}

.modal-header-image {
  overflow: hidden;
  border-radius: $avr-modal-border-radius $avr-modal-border-radius 0 0;

  .close {
    position: absolute;
    top: $modal-inner-padding;
    right: $modal-inner-padding;
    color: $avr-color-gray-10;
  }

  img {
    width: 100%;
  }
}

.modal-body {
  padding: $modal-inner-padding;

  & > *:first-child .pagination-header {
    margin-top: 0;
  }
}

// Bootstrap 3.3.1 drops this due to some incompatability with scrollbars when the modal is scrollable
// Works for us for now though...
.modal-backdrop {
  background-color: $avr-depth-z300-backdrop;
  position: fixed;
  bottom: 0;
  transition: opacity 0s;

  &.in {
    opacity: 1;
  }
}

.modal-xl {
  width: 815px;
}

.modal-xxl {
  width: auto;
  margin: 10px;
}

.modal-backdrop.modal-backdrop-dark.in {
  opacity: 0.7;
}

@media (min-width: 1120px) {
  .modal-xxl {
    width: 1000px;
    margin: 30px auto;
  }
}

// Makes part of a modal scrollable
.modal-scroll-content {
  max-height: 40vh;
  overflow: auto;
}

// Helps to compensate for dropdowns / date popups not flowing out of the scrollable container
.modal-scroll-content-popup-friendly {
  height: 40vh;
  padding-bottom: 150px;
}

.modal-tabs {
  margin-top: -$avr-content-padding;

  &:first-of-type .nav-tabs {
    padding-top: 0;
  }

  .wizard & {
    margin-top: 0;
  }
}

.modal-open.blur {
  .main-layout {
    filter: blur(1px);
  }
}
